<template>
    <div class="pty-next">
        <div class="pty-next__steps">
            <span v-for="i in steps" :key="i" :class="{'active': i === current}"></span>
        </div>

        <div class="pty-next__button" @click="next">
            <h2>{{$t('Common.next')}}</h2>
            <PtyButton light :no-padding="true"  :loading="loading" :circle="true" id="pty__next">
                <nextArrow />
            </PtyButton>
        </div>
    </div>
</template>

<script>
import PtyButton from './PtyButton.vue';
import nextArrow from '../assets/images/icons/forward-arrow.svg';
export default {
    name: 'PtyNext',
    components: {
        PtyButton,
        nextArrow
    },
    props: {
        steps: {
            type: Number,
            default: 1
        },

        current: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            loading: false       
        }
    },

    methods: {
        next() {
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
                this.$emit('next');
            }, 500);
        }
    }
}
</script>

<style >
.pty-next h2 {
    margin: 0;
    color: rgba(var(--vs-primary));
    margin-top: 10px;
}
.pty-next {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding-top:0!important;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    background: #fff;
    height: 70px;
}

.pty-next__button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
}

.pty-next__button h2, .pty-next__button .vs-button {
    margin: 0;
}

.pty-next__steps {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row;
    gap: 0.5rem;
    height:100%;
    padding-bottom:2rem;
}

.pty-next__steps span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #EDEDED;
}

.pty-next__steps span.active {
    border-radius: 35%;
    background: rgba(var(--vs-primary));
    width: 20px;
    transition: all 0.3s;
}

/* parent of pty-next must have a padding bottom of 20px */
*:has(.pty-next) {
    height: calc(100svh - 70px);
    overflow-y: auto!important;
}
</style>